/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, FormEvent, useRef } from "react";
import { AddEditBeneficiaryForm, AddEditBeneficiaryFormErrors } from "../../interfaces/formValidation";
import { ObjectUtils } from "../../utils";
import { USER_TOKEN } from "../../utils/Storage";
import { FormComponent, ModalComponent } from "..";
import { createBeneficiary, getBeneficiaries } from "../../actions";
import { useDispatch } from "react-redux";

export const AddNewBeneficiaryComponent = (props: any) => {
  const { selectedBenefitID, beneficiaries, isEditMode, beneficiary, benefiaryType, beneficiariesFull, closeAddNewDependentModel, isBeneficiariesAll } = props;
  const textInputRefs = useRef<HTMLInputElement[]>([]);
  const dispatch = useDispatch();
  const editMode = isEditMode && benefiaryType === "person" ? true : false;
  // const [checkDOB, setCheckDOB] = useState<boolean>(false);
  const localestring = USER_TOKEN?.get()?.lang?.substring(0, 2);
  const [dateValid, setDateValid] = useState<boolean>(true);

  const [isEnabled, setSaveButtonEnabled] = useState<boolean>(false);
  const [showDesc, setDesc] = useState<any>({
    showDesc: false,
    description: "",
    descTitle: ""
  });
  const [infoErrorMessage, setinfoErrorMessage] = useState<AddEditBeneficiaryFormErrors>({
    firstName: "",
    lastName: "",
    initial: "",
    trustee: "",
    charity: "",
    estate: "",
    dateOfBirth: "",
    isValid: true
  });

  const [addEditBeneficiary, setAddEditBeneficiary] = useState<AddEditBeneficiaryForm>({
    relationshipID: editMode ? beneficiary?.relationshipID : null,
    formValidated: false,
    beneficiaryType: benefiaryType ? benefiaryType : "person",
    revocable: editMode ? (beneficiary?.isRevocable === 1 ? true : false) : true,
    firstName: editMode ? beneficiary?.name.split(" ")[0] : "",
    initial: editMode ? beneficiary?.initial : "",
    lastName: editMode ? beneficiary?.name.split(" ")[1] : "",
    dateOfBirth: {
      year: editMode ? (beneficiary?.birthDate && beneficiary?.birthDate?.year ? beneficiary?.birthDate?.year : "") : "",
      month: editMode ? (beneficiary?.birthDate && beneficiary?.birthDate?.month ? beneficiary?.birthDate?.month : "") : "",
      day: editMode ? (beneficiary?.birthDate && beneficiary?.birthDate?.day ? beneficiary?.birthDate?.day : "") : ""
    },
    trustee: editMode ? beneficiary?.trustee : "",
    fullName: "",
    charity: isEditMode && benefiaryType === "charity" ? beneficiary?.name : "",
    estate: isEditMode && benefiaryType === "estate" ? beneficiary?.name : "",
    beneficiaryId: !isEditMode ? -1 : beneficiary?.beneficiaryId
  });
  const contents1 = beneficiariesFull && beneficiariesFull?.contentResource && beneficiariesFull?.contentResource?.contents ? beneficiariesFull?.contentResource.contents : [];
  const contents2 =
    beneficiariesFull && beneficiariesFull?.contentResource && beneficiariesFull?.contentResource?.controlResource ? beneficiariesFull?.contentResource.controlResource?.BenefitBeneficiaries : [];

  const contents: any = [...contents1, ...contents2];

  const lblRevocableText = ObjectUtils.extractLabelFromObject(contents, "lblTooltipRevocable");
  const lblTooltipTrusteeTitle = ObjectUtils.extractLabelFromObject(contents, "lblTooltipTrusteeTitle");
  const lblTooltipTrustee = ObjectUtils.extractLabelFromObject(contents, "lblTooltipTrustee");
  const lblIrrevocableText = ObjectUtils.extractLabelFromObject(contents, "lblTooltipIrrevocable");
  const lblInstructionsAdd = ObjectUtils.extractLabelFromObject(contents, "lblInstructionsAdd");
  const lblFirstName = ObjectUtils.extractLabelFromObject(contents, "lblFirstName");
  const lblInitial = ObjectUtils.extractLabelFromObject(contents, "lblInitial");
  const lblLastName = ObjectUtils.extractLabelFromObject(contents, "lblLastName");
  const lblRelationship = ObjectUtils.extractLabelFromObject(contents, "lblRelationship");
  const lblRevocableLabel = ObjectUtils.extractLabelFromObject(contents, "lblRevocableLabel");
  const lblRevocable = ObjectUtils.extractLabelFromObject(contents, "lblRevocable");
  const lblIrrevocable = ObjectUtils.extractLabelFromObject(contents1, "lblIrrevocable");
  const lblTrustee = ObjectUtils.extractLabelFromObject(contents, "lblTrustee");
  const lblBirthDate = ObjectUtils.extractLabelFromObject(contents, "lblBirthDate");
  const lblBeneficiaryType = ObjectUtils.extractLabelFromObject(contents, "lblBeneficiaryType");
  const lblCharity = ObjectUtils.extractLabelFromObject(contents, "lblCharity");
  const lblEstate = ObjectUtils.extractLabelFromObject(contents, "lblEstate");
  const lblBeneficiaryCharityNamePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblBeneficiaryCharityNamePlaceHolder");
  const lblBeneficiaryFirstNamePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblBeneficiaryFirstNamePlaceHolder");
  const lblBeneficiaryInitialPlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblBeneficiaryInitialPlaceHolder");
  const lblBeneficiaryLastNamePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblBeneficiaryLastNamePlaceHolder");
  const lblBeneficiaryTrusteePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblBeneficiaryTrusteePlaceHolder");
  const benfitRevocable = addEditBeneficiary?.revocable ? lblRevocableText : lblIrrevocableText;
  const benfitRevocableTitle = addEditBeneficiary?.revocable ? lblRevocable.display : lblIrrevocable.display;
  const btnSave = ObjectUtils.extractLabelFromObject(contents, "btnSave");
  const btnCancel = ObjectUtils.extractLabelFromObject(contents, "btnCancel");
  const lblDatePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblDatePlaceHolder");
  const rfvFirstNameErrorMessage = ObjectUtils.extractLabelFromObject(contents, "rfvFirstNameErrorMessage");
  const rfvLastNameErrorMessage = ObjectUtils.extractLabelFromObject(contents, "rfvLastNameErrorMessage");
  const rfvCharityNameErrorMessage = ObjectUtils.extractLabelFromObject(contents, "rfvCharityNameErrorMessage");
  const cmpRelationshipErrorMessage = ObjectUtils.extractLabelFromObject(contents, "cmpRelationshipErrorMessage");
  const lblInstructionsAllEdit = ObjectUtils.extractLabelFromObject(contents, "lblInstructionsAllEdit");

  const beneficiaryTypesEstate = [
    { text: beneficiariesFull?.typeList[0], value: "person" },
    { text: beneficiariesFull?.typeList[1], value: "charity" },
    { text: beneficiariesFull?.typeList[2], value: "estate" }
  ];
  const beneficiaryTypes = [
    { text: beneficiariesFull?.typeList[0], value: "person" },
    { text: beneficiariesFull?.typeList[1], value: "charity" }
  ];

  const relationshipList = beneficiariesFull?.relationshipList;
  if (relationshipList[0]?.value !== "0")
    relationshipList?.unshift({
      value: "0",
      text: localestring === "en-CA" || localestring === "en" ? "Select" : "Sélectionner"
    });

  const revocableList = [
    { text: lblRevocable.display, value: true },
    { text: lblIrrevocable.display, value: false }
  ];

  const handleDateChange = (eventDate: any) => {
    const dateOfBirth = { ...eventDate };
    if (dateOfBirth.year === "NaN" || dateOfBirth.year === "") {
      dateOfBirth.day = null;
      dateOfBirth.month = null;
      dateOfBirth.year = null;
    }
    eventDate = dateOfBirth;
    setAddEditBeneficiary({
      ...addEditBeneficiary,
      dateOfBirth: eventDate
    });
  };

  const isValidation = () => {
    let isValid = false;

    if (addEditBeneficiary.beneficiaryType === "person") {
      // setCheckDOB(
      //   addEditBeneficiary?.dateOfBirth.year === null ||
      //     addEditBeneficiary?.dateOfBirth.year === "" ||
      //     addEditBeneficiary?.dateOfBirth.year === "NaN" ||
      //     addEditBeneficiary?.dateOfBirth.year.length < 4
      // );

      if (!addEditBeneficiary.firstName) {
        setAddEditBeneficiary({
          ...addEditBeneficiary,
          formValidated: true
        });
        textInputRefs.current[0]?.focus();
        isValid = true;
      } else if (!addEditBeneficiary?.lastName) {
        setAddEditBeneficiary({
          ...addEditBeneficiary,
          formValidated: true
        });
        textInputRefs.current[1]?.focus();
        isValid = true;
      } else if (addEditBeneficiary?.relationshipID === null || addEditBeneficiary?.beneficiaryId === "0") {
        setAddEditBeneficiary({
          ...addEditBeneficiary,
          formValidated: true
        });
        isValid = true;
      }
    }

    if (addEditBeneficiary.beneficiaryType === "charity") {
      if (!addEditBeneficiary.charity) {
        setAddEditBeneficiary({
          ...addEditBeneficiary,
          formValidated: true
        });
        isValid = true;
      }
    }

    setSaveButtonEnabled(isValid);
    return isValid;
  };

  const handleSubmit = (event: FormEvent<EventTarget>): void => {
    event.preventDefault();
    //dissmiss();
    if (!isValidation()) {
      setSaveButtonEnabled(true);
      const beneficiaryData = { ...addEditBeneficiary };
      //addEditBeneficiary.fullName = `${addEditBeneficiary.firstName} ${addEditBeneficiary.lastName}`;
      if (beneficiaryData?.dateOfBirth /*&& beneficiary.dateOfBirth.month*/ && beneficiaryData?.dateOfBirth.year && beneficiaryData?.dateOfBirth.day)
        beneficiaryData.dateOfBirth.month = beneficiaryData?.dateOfBirth.month;
      else beneficiaryData.dateOfBirth = null;

      dispatch(
        createBeneficiary(
          beneficiaryData,
          () => {
            closeAddNewDependentModel();
            getBeneficiaries(selectedBenefitID, isBeneficiariesAll);
          },
          (errorMessage) => {
            setinfoErrorMessage(errorMessage);
            setSaveButtonEnabled(false);
          }
        )
      );
    }
  };

  const onInputChange = (value: any) => {
    isValidInfo(value);
    setSaveButtonEnabled(false);
    setAddEditBeneficiary({
      ...addEditBeneficiary,
      ...value
    });
  };

  const handleSelection = (callback: any) => {
    setSaveButtonEnabled(false);
    const name = Object.keys(callback)[0];
    const newcallback: any = { ...callback };
    if (name === "revocable") {
      newcallback[name] = Object.values(callback)[0] === "true" ? true : false;
    } else {
      newcallback[name] = Object.values(callback)[0];
    }
    setAddEditBeneficiary({
      ...addEditBeneficiary,
      ...newcallback
    });
  };

  const isValidInfo = (value: any) => {
    const regCharityNameErrorMessage = ObjectUtils.extractLabelFromObject(contents2, "regCharityNameErrorMessage").display;
    const regFirstNameErrorMessage = ObjectUtils.extractLabelFromObject(contents2, "regFirstNameErrorMessage").display;
    const regLastNameErrorMessage = ObjectUtils.extractLabelFromObject(contents2, "regLastNameErrorMessage").display;
    const regTrusteeEstateErrorMessage = ObjectUtils.extractLabelFromObject(contents2, "regTrusteeEstateErrorMessage").display;

    const { validationforFirstName, validationforLastName, validationforInitial, validationforCharity, validationforEstate } = props?.beneficiariesFull || {};
    if (value.firstName !== undefined) {
      if (ObjectUtils.isTest(value?.firstName, validationforFirstName)) {
        infoErrorMessage.firstName = regFirstNameErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.firstName = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.lastName !== undefined) {
      if (ObjectUtils.isTest(value?.lastName, validationforLastName)) {
        infoErrorMessage.lastName = regLastNameErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.lastName = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.initial !== undefined) {
      if (ObjectUtils.isTest(value?.initial, validationforInitial)) {
        infoErrorMessage.initial = "";
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.initial = "";
        infoErrorMessage.isValid = true;
      }
    }
    if (value.charity !== undefined) {
      if (ObjectUtils.isTest(value?.charity, validationforCharity)) {
        infoErrorMessage.charity = regCharityNameErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.charity = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.estate !== undefined) {
      if (ObjectUtils.isTest(value?.estate, validationforEstate)) {
        infoErrorMessage.estate = regTrusteeEstateErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.estate = "";
        infoErrorMessage.isValid = true;
      }
    }

    setinfoErrorMessage(infoErrorMessage);
  };

  const toggleDescription = (title: string, desc: string) => {
    setDesc({ showDesc: true, description: desc, descTitle: title });
  };

  const handleClose = () => {
    setDesc({ showDesc: false, description: "", descTitle: "" });
  };

  const gridRowData = [
    /*this array object is for first name section*/
    [
      !isEditMode && {
        type: "choicelist",
        colclassname: "ml-popupsection ml-newdependent",
        mode: "edit",
        options: {
          type: "radio",
          selected: addEditBeneficiary?.beneficiaryType ? addEditBeneficiary?.beneficiaryType.toString() : benefiaryType,
          name: "beneficiaryType",
          legendtext: lblBeneficiaryType.display,
          optionsinput: beneficiariesFull?.canAddEstate ? beneficiaryTypesEstate : beneficiaryTypes,
          width: "50",
          fgclass: "mt-2 mb-4 float-left",
          handlechange: handleSelection,
          wrapperclass: "ml-radiolist"
        }
      }
    ],

    [
      addEditBeneficiary?.beneficiaryType === "charity" && {
        type: "text",
        display: `${lblCharity.display}<span class="ml-mandatory-sign">*</span> `,
        maxLength: 50,
        name: "charity",
        labeltext: lblBeneficiaryCharityNamePlaceHolder?.display,
        texttoread: lblBeneficiaryCharityNamePlaceHolder?.textToRead,
        value: addEditBeneficiary?.charity,
        mode: "edit",
        colclassname: "col-sm-12 col-md-4",
        lblclassname: "edit-input-label mb-2",
        handlechange: onInputChange,
        error: rfvCharityNameErrorMessage.display,
        required: true
      },
      addEditBeneficiary?.beneficiaryType === "estate" && {
        type: "text",
        display: lblEstate.display,
        maxLength: 50,
        name: "estate",
        labeltext: lblEstate?.display,
        texttoread: lblEstate?.textToRead,
        value: addEditBeneficiary?.estate,
        mode: "edit",
        colclassname: "col-sm-12 col-md-4",
        lblclassname: "edit-input-label mb-2",
        handlechange: onInputChange,
        required: false
      }
    ],
    addEditBeneficiary?.beneficiaryType === "person" && [
      {
        type: "text",
        display: `${lblFirstName.display}<span class="ml-mandatory-sign">*</span> `,
        maxLength: 50,
        name: "firstName",
        labeltext: lblBeneficiaryFirstNamePlaceHolder?.display,
        texttoread: lblBeneficiaryFirstNamePlaceHolder?.textToRead,
        value: addEditBeneficiary?.firstName,
        mode: "edit",
        colclassname: "col-sm-12 col-md-4",
        lblclassname: "edit-input-label mb-2",
        error: rfvFirstNameErrorMessage?.display,
        required: true,
        handlechange: onInputChange
      },
      {
        type: "text",
        display: lblInitial.display,
        texttoread: lblBeneficiaryInitialPlaceHolder?.textToRead,
        maxLength: 1,
        name: "initial",
        value: addEditBeneficiary?.initial,
        mode: "edit",
        colclassname: "col-sm-12 col-md-4",
        lblclassname: "edit-input-label mb-2",
        required: false,
        handlechange: onInputChange
      },
      {
        type: "text",
        display: `${lblLastName.display}<span class="ml-mandatory-sign">*</span> `,
        texttoread: lblBeneficiaryLastNamePlaceHolder?.textToRead,
        maxLength: 50,
        name: "lastName",
        mode: "edit",
        colclassname: "col-sm-12 col-md-4",
        value: addEditBeneficiary?.lastName,
        lblclassname: "edit-input-label mb-2",
        error: rfvLastNameErrorMessage.display,
        required: true,
        handlechange: onInputChange
      }
    ],
    /* this section is for gender only*/
    addEditBeneficiary?.beneficiaryType === "person" && [
      {
        type: "select",
        name: "relationshipID",
        texttoread: lblRelationship.textToRead,
        display: lblRelationship.display,
        text: lblRelationship.display,
        selectedvalue: addEditBeneficiary?.relationshipID === null ? 0 : addEditBeneficiary?.relationshipID,
        options: relationshipList,
        colclassname: "col-sm-12 col-md-8",
        mode: "edit",
        error: (addEditBeneficiary?.relationshipID === null || addEditBeneficiary?.relationshipID === "0") && addEditBeneficiary?.formValidated ? cmpRelationshipErrorMessage.display : "",
        wrappererrorclass: "d-block",
        required: true,
        handlechange: handleSelection
      },
      {
        colclassname: "col-sm-12 col-md-4 p-0",
        className: "ml-datecomponent p-0",
        name: "dateOfBirth",
        type: "calendar",
        placeholder: lblDatePlaceHolder.display,
        display: lblBirthDate.display,
        texttoread: lblDatePlaceHolder.textToRead,
        mode: "edit",
        date: addEditBeneficiary?.dateOfBirth,
        isreadonly: "true",
        datevalid: dateValid?.toString(),
        addnew: "true",
        wrapperclass: "ml-inputfields ml-dateofbirth",
        onChange: handleDateChange
      }
    ],
    addEditBeneficiary?.beneficiaryType === "person" && [
      {
        colclassname: "col-sm-12 col-md-12",
        type: "instructions",
        display: isEditMode ? lblInstructionsAllEdit.display : lblInstructionsAdd.display,
        classname: "secondary"
      }
    ],
    addEditBeneficiary?.beneficiaryType === "person" && [
      {
        type: "text",
        display: lblTrustee.display,
        texttoread: lblBeneficiaryTrusteePlaceHolder?.textToRead,
        maxLength: 50,
        tooltip: {
          type: "tooltipicon",
          className: "tooltip-icon icon-action ms-2",
          onSubmit: () => toggleDescription(lblTooltipTrusteeTitle.textToRead, lblTooltipTrustee.textToRead)
        },
        value: addEditBeneficiary?.trustee,
        name: "trustee",
        mode: "edit",
        colclassname: "col-sm-12 col-md-4",
        lblclassname: "edit-input-label mb-2",
        required: false,
        handlechange: onInputChange
      }
    ],
    [
      addEditBeneficiary?.beneficiaryType === "person" &&
        beneficiariesFull?.showIsRevocable === true && {
          type: "choicelist",
          colclassname: "ml-popupsection ml-newdependent",
          mode: "edit",
          options: {
            type: "radio",
            selected: addEditBeneficiary?.revocable.toString() === "true" ? true : false,
            name: "revocable",
            legendtext: lblRevocableLabel.display,
            optionsinput: revocableList,
            width: "50",
            fgclass: "float-left",
            // wrapperclass: "ml-radiolist",
            handlechange: handleSelection,
            tooltip: {
              type: "tooltipicon",
              className: "tooltip-icon icon-action ms-2",
              onSubmit: () => toggleDescription(benfitRevocableTitle, benfitRevocable.display)
            }
          }
        }
    ]
  ];
  const formInput = {
    classname: "loginform",
    validated: addEditBeneficiary?.formValidated,
    handlesubmit: handleSubmit
  };
  const buttonInput = [
    {
      variant: "outline-secondary btn-cancel",
      display: btnCancel?.display,
      onClick: closeAddNewDependentModel
    },
    {
      type: "submit",
      variant: "outline-secondary btn-save",
      display: btnSave?.display,
      disabled: isEnabled
    }
  ];

  const descriptionModalObject = {
    showModal: showDesc?.showDesc,
    clickHandler: handleClose,
    dialogClass: "infopopup",
    head: {
      modalTitle: showDesc?.showDesc && showDesc?.descTitle
    },
    body: {
      messageText: showDesc?.description
    }
  };

  return (
    <>
      <div className="ml-card card mt-3">
        <div className={`${editMode ? "ml-radiolist clearfix" : ""}`}>
          <div className="formErrors">
            {infoErrorMessage &&
              Object.keys(infoErrorMessage).map((fieldName, i) => {
                if (infoErrorMessage[fieldName]?.length > 0) {
                  return (
                    <p className="formErrorPStyle" key={i} role="alert">
                      {infoErrorMessage[fieldName]}
                    </p>
                  );
                } else {
                  return "";
                }
              })}
          </div>
        </div>
        <FormComponent
          formdata={formInput}
          gridRowData={gridRowData}
          btndata={buttonInput}
          rowclassname={"mb-3"}
          btnviewmode={1}
          btnviewclass="d-flex justify-content-between ml-popupbuttons border-top pt-3"
        />
        {showDesc?.showDesc && <ModalComponent modalObject={descriptionModalObject} />}
      </div>
    </>
  );
};

export default AddNewBeneficiaryComponent;
